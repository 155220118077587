<template>
  <div class="home-wrapper">
    <header-bar>
      <img
        src="~@/assets/img/切图_web_0628/logo-02.png"
        width="49px"
        height="49px"
      />
      <span class="header-title">艾佳德</span>
    </header-bar>
    <nav-bar />
    <Banner
      :backgroundImage="bannerConfig.backgroundImage"
      :iconImg="bannerConfig.iconImg"
      :title="bannerConfig.title"
      :desc="bannerConfig.desc"
    />

    <div class="course">
      <div class="bm-content-container">
        <div class="item heart">
          <div
            class="item-content"
            @click="$router.push('/psychologicalConsulting')"
          >
            <div class="text">
              <p>心理咨询</p>
              <p>专业技能培训</p>
            </div>
            <svg-icon icon-class="butten_right" width="28px" height="23px" />
          </div>
        </div>
        <div class="item family">
          <div class="item-content" @click="$router.push('/homeEducation')">
            <div class="text">
              <p>家庭教育指导师</p>
              <p>专业技能培训</p>
            </div>
            <svg-icon icon-class="butten_right" width="28px" height="23px" />
          </div>
        </div>
        <div class="item online">
          <div class="item-content" @click="$router.push('/excellentCourses')">
            <div class="text">
              <p>线上精品课程</p>
            </div>
            <svg-icon icon-class="butten_right" width="28px" height="23px" />
          </div>
          <!-- <a
            class="item-content"
            href="http://xinlizixun.aijiad.com/portal/course"
            target="_blanck"
          >
            <div class="text">
              <p>线上精品课程</p>
            </div>
            <svg-icon icon-class="butten_right" width="28px" height="23px" />
          </a> -->
        </div>
      </div>
    </div>

    <div class="teacher">
      <div class="bm-content-container">
        <div class="top">
          <div class="publicTitle">
            <span class="title-zh">师资介绍</span>
            <!-- <span class="title-cn">TEACHERS</span> -->
          </div>
          <div class="swiper-pagination"></div>
        </div>

        <div class="content">
          <div class="swiper-container">
            <!-- 如果需要分页器 -->

            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in list"
                :key="index"
              >
                <div
                  class="teacher-item"
                  v-for="(child, childIndex) in item"
                  :key="childIndex"
                >
                  <img :src="child.pic" width="240px" height="300px" alt="" />
                  <div class="name-zh">{{ child.name_zh }}</div>
                  <!-- <div class="name-cn">{{ child.name_cn }}</div> -->

                  <div class="line"></div>
                  <div
                    class="title"
                    v-for="title in child.titles"
                    :key="title"
                    v-html="title"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="notice">
      <div class="bm-content-container">
        <div class="publicTitle">
          <span class="title-zh">通知公告</span>
          <!-- <span class="title-cn">NOTICE NOTICE</span> -->
        </div>
        <div class="content">
          <ul class="content-ul">
            <li
              v-for="item in notice"
              :key="item.title"
              @click="showNotice(item)"
            >
              <div class="title">{{ item.title }}</div>
              <div class="date">{{ item.date }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="notice_dialog.visible"
      :title="notice_dialog.title"
    >
      <div class="img-box-notice">
        <img :src="notice_dialog.pic" alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import HeaderBar from "./components/header.vue";
import NavBar from "./components/nav.vue";
import Banner from "./components/banner.vue";
import { multList } from "@/utils";
import iconImg from "@/assets/img/切图_web_0628/关于aijiade.png";
import backgroundImage from "@/assets/img/bg_首页.png";
import axios from "axios";

import { teachersList, noticeList } from "./data";

export default {
  components: {
    HeaderBar,
    NavBar,
    Banner,
  },
  data() {
    return {
      list: [],
      notice: noticeList,
      notice_dialog: {
        visible: false,
        title: "",
        pic: "",
      },
      bannerConfig: {
        backgroundImage: backgroundImage,
        iconImg: iconImg,
        title: "关于艾佳德",
        desc: {
          one: "北京艾佳德科技发展有限公司成立于2013年11月26日，公司成立至今，专注于心理学教育培训工作，<br/>组织开展心理学基础培训，继续教育培训，督导与职业伦理培训等。",
          two: "本公司为中国心理卫生协会心理咨询项目的承办单位，我们将凝聚更多心理学专业学者、专家，<br/>陪伴合作机构共同传播心理健康知识。",
        },
      },
    };
  },
  created() {
    this.list = multList(teachersList, 4);
  },
  mounted() {
    new Swiper(".swiper-container", {
      //direction: 'vertical', // 垂直切换选项
      //mousewheel: true, //滚轮
      loop: true, // 循环模式选项
      autoplay: {
        delay: 5000,
      },

      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
        clickable: true, // 分页器可以点击
      },
    });
    // this.getInfo();
  },
  methods: {
    getInfo() {
      axios
        .get("/admin/system/official_website/", {
          params: {
            name: this.query,
          },
        })
        .then((response) => {
          const res = response.data;
          if (res.code === 200) {
            this.queryList = res.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    showNotice(item) {
      this.notice_dialog.title = item.title;
      this.notice_dialog.pic = item.pic;
      this.notice_dialog.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.home-wrapper {
  .header-title {
    font-size: 34px;
    color: #fff;
    font-weight: 500;
    margin-left: 5px;
  }

  .course {
    width: 100%;
    height: 358px;

    .bm-content-container {
      display: flex;
      justify-content: center;
    }

    .item + .item {
      margin-left: 30px;
    }
    .item {
      width: 299px;
      height: 277px;
      position: relative;
      // background-image: url("../../icons/svg/butten_bg_一行文字.svg");
    }

    .item-content {
      position: absolute;
      bottom: 0;
      left: 0;
      cursor: pointer;

      width: 100%;
      height: 114px;

      display: flex;
      justify-content: space-around;
      align-items: center;

      .text p {
        font-size: 24px;
        color: #fff;
        line-height: 33px;
      }
    }

    .heart {
      background-image: url("~@/assets/img/bg_psychological_counseling.png");

      .item-content {
        background-image: url("~@/assets/img/butten_bg_两行文字.png");
      }
    }
    .family {
      background-image: url("~@/assets/img/bg_家庭教育指导师.png");
      .item-content {
        background-image: url("~@/assets/img/butten_bg_两行文字.png");
      }
    }
    .online {
      background-image: url("~@/assets/img/bg_线上精品课程.png");
      .item-content {
        height: 80px;
        background-image: url("~@/assets/img/butten_bg_一行文字.png");
      }
    }
  }

  .teacher {
    width: 100%;
    background: rgba(245, 245, 245, 1);

    .content {
      margin-top: 30px;
    }
  }
  .teacher {
    .bm-content-container {
      padding: 60px 100px;
    }
    .top {
      position: relative;
      text-align: center;
      // display: flex;
      // justify-content: space-between;

      .swiper-pagination {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-top: 10px;

      .swiper-container {
        margin-top: 30px;
        height: 550px;

        .swiper-slide {
          display: flex;
          height: 100%;

          .teacher-item + .teacher-item {
            margin-left: 10px;
          }
          .teacher-item {
            width: 240px;
            height: 100%;
            background: #b98862;

            display: flex;
            align-items: center;
            flex-direction: column;
            position: relative;

            .name-zh {
              font-size: 20px;
              font-weight: 500;
              color: #ffffff;
              margin-top: 10px;
              // line-height: 28px;
            }

            .name-cn {
              font-size: 16px;
              font-weight: 600;
              color: rgba(255, 255, 255, 0.4);
              // line-height: 22px;

              // position: absolute;
              // top: 305px;
            }

            .line {
              width: 20px;
              height: 1px;
              background: #fff;
              margin-top: 8px;
              margin-bottom: 3px;
            }

            .title {
              font-size: 14px;
              font-weight: 400;
              color: #ffffff;
              line-height: 27px;
              text-align: center;
              padding: 0 10px;
            }
          }
        }
      }
    }
  }

  .notice {
    width: 100%;
    height: 438px;
    background: #fff;

    .content {
      margin-top: 30px;
      background: #f5f5f5;
      padding: 30px 60px;

      .content-ul {
        li + li {
          border-top: 1px solid rgba(0, 0, 0, 0.1);
        }
        li {
          height: 49px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          .title {
            color: rgba(0, 0, 0, 1);
            font-size: 16px;
          }
        }
      }
    }
  }
  .publicTitle {
    font-size: 28px;
    font-weight: bold;
    color: rgba(40, 40, 40, 1);
    text-align: center;
    .title-zh {
      // margin-left: 112px;
    }
    // .title-cn {
    //   margin-left: 10px;
    // }
  }
}
</style>
<style lang="scss">
.swiper-pagination {
  width: 200px;
  text-align: right;

  span {
    margin-left: 10px;
  }
}

.img-box-notice {
  text-align: center;
  height: 450px;
  overflow-y: scroll;

  img {
    width: 542px;
    height: auto;
  }
}
</style>
